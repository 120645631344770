var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hidden-danger-category flex"},[_c('div',{staticClass:"left flex-column"},[_c('div',{staticClass:"nameTit flex-center-v"},[_c('span',[_vm._v("隐患类别")]),(_vm.isCompany&&(_vm.isTissueAdmin||_vm.isAdmin))?_c('el-button',{staticStyle:{"margin-left":"20px"},attrs:{"type":"primary","size":"mini","plain":""},on:{"click":_vm.showFormTank}},[_vm._v("新增隐患类别")]):_vm._e()],1),_c('div',{staticClass:"cont flex-1-auto"},[_c('el-tree',{ref:"tree",staticClass:"filter-tree",attrs:{"data":_vm.dataTree,"node-key":"id","props":_vm.defaultProps,"default-expand-all":"","expand-on-click-node":false},on:{"node-click":_vm.handleNodeClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('span',{staticClass:"custom-tree-node"},[_c('span',[_vm._v(_vm._s(node.label))]),(_vm.isCompany&&(_vm.isTissueAdmin||_vm.isAdmin))?_c('span',{staticClass:"flex-center-v"},[(!data.status)?_c('el-button',{attrs:{"type":"text","size":"mini","disabled":""}},[_vm._v("类别已作废")]):[_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.add(data); })($event)}}},[_vm._v("新增隐患类别")]),_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.edit(data); })($event)}}},[_vm._v("编辑")])]],2):_vm._e()])}}])})],1)]),_c('div',{staticClass:"right flex-column"},[_vm._m(0),_c('div',{staticClass:"cont flex-1-auto"},[_c('el-table',{ref:"multipleTable",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableList,"height":"100%","tooltip-effect":"light myTooltips"}},[_c('el-table-column',{attrs:{"type":"index","label":"序号","align":"center"}}),_c('el-table-column',{attrs:{"prop":"name","label":"类别名称","align":"center"}}),_c('el-table-column',{attrs:{"prop":"createTime","label":"创建时间","align":"center"}}),_c('el-table-column',{attrs:{"label":"状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.status ? '启用中' : '作废中'))])]}}])}),(_vm.isCompany&&(_vm.isTissueAdmin||_vm.isAdmin))?_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!row.status)?_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.enableOrDisable(row, 'star')}}},[_vm._v("启用")]):_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.enableOrDisable(row, 'end')}}},[_vm._v("作废")])]}}],null,false,3747750452)}):_vm._e()],1)],1),_c('div',{staticClass:"flex-center",staticStyle:{"padding-top":"10px"}},[(_vm.searchData.total > _vm.searchData.pageSize)?_c('el-pagination',{attrs:{"background":"","current-page":_vm.searchData.pageCurrent,"page-size":_vm.searchData.pageSize,"total":_vm.searchData.total,"layout":"total, prev, pager, next"},on:{"update:currentPage":function($event){return _vm.$set(_vm.searchData, "pageCurrent", $event)},"update:current-page":function($event){return _vm.$set(_vm.searchData, "pageCurrent", $event)},"current-change":_vm.handleSizeChangeData}}):_vm._e()],1)]),_c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.showTank,"width":"30%","close-on-click-modal":false},on:{"update:visible":function($event){_vm.showTank=$event},"close":_vm.close}},[_c('el-form',{ref:"form",staticClass:"demo-form-inline",attrs:{"model":_vm.formData,"rules":_vm.rules,"label-width":"120px"}},[_c('el-form-item',{attrs:{"label":"隐患类别名称","prop":"name"}},[_c('el-input',{attrs:{"placeholder":"请输入名称"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.save}},[_vm._v("确 定")]),_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){_vm.showTank = false}}},[_vm._v("取 消")])],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nameTit flex-center-v"},[_c('span',[_vm._v("隐患类别列表")])])}]

export { render, staticRenderFns }