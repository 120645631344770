<template>
  <div class="hidden-danger-category flex">
    <div class="left flex-column">
      <div class="nameTit flex-center-v">
        <span>隐患类别</span>
        <el-button type="primary" size="mini" plain style="margin-left: 20px" @click="showFormTank" v-if="isCompany&&(isTissueAdmin||isAdmin)">新增隐患类别</el-button>
      </div>
      <div class="cont flex-1-auto">
        <el-tree
          class="filter-tree"
          :data="dataTree"
          node-key="id"
          :props="defaultProps"
          default-expand-all
          :expand-on-click-node="false"
          @node-click="handleNodeClick"
          ref="tree"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
            <span class="flex-center-v"  v-if="isCompany&&(isTissueAdmin||isAdmin)">
              <el-button v-if="!data.status" type="text" size="mini" disabled>类别已作废</el-button>
              <template v-else>
                <el-button type="text" size="mini" @click.stop="() => add(data)">新增隐患类别</el-button>
                <el-button type="text" size="mini" @click.stop="() => edit(data)">编辑</el-button>
              </template>
              <!-- <el-button type="text" size="mini" @click.stop="() => enableOrDisable(data, 'star')">启用</el-button>-->
              <!-- <el-button type="text" size="mini" @click.stop="() => enableOrDisable(data, 'end')">作废</el-button>-->
            </span>
          </span>
        </el-tree>
      </div>
    </div>
    <div class="right flex-column">
      <div class="nameTit flex-center-v">
        <span>隐患类别列表</span>
      </div>
      <div class="cont flex-1-auto">
        <el-table ref="multipleTable" :data="tableList" height="100%" style="width: 100%" tooltip-effect="light myTooltips">
          <el-table-column type="index" label="序号" align="center"></el-table-column>
          <el-table-column prop="name" label="类别名称" align="center"></el-table-column>
          <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
          <el-table-column label="状态" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.status ? '启用中' : '作废中' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center"  v-if="isCompany&&(isTissueAdmin||isAdmin)">
            <template slot-scope="{ row }">
              <el-button v-if="!row.status" type="text" size="mini" @click="enableOrDisable(row, 'star')">启用</el-button>
              <el-button v-else type="text" size="mini" @click="enableOrDisable(row, 'end')">作废</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="flex-center" style="padding-top: 10px">
        <el-pagination
            v-if="searchData.total > searchData.pageSize"
            background
            :current-page.sync="searchData.pageCurrent"
            :page-size="searchData.pageSize"
            :total="searchData.total"
            layout="total, prev, pager, next"
            @current-change="handleSizeChangeData"
        ></el-pagination>
      </div>
    </div>
    <el-dialog :title="title" :visible.sync="showTank" width="30%" :close-on-click-modal="false" @close="close">
      <el-form ref="form" :model="formData" :rules="rules" class="demo-form-inline" label-width="120px">
        <el-form-item label="隐患类别名称" prop="name">
          <el-input v-model="formData.name" placeholder="请输入名称"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="save">确 定</el-button>
        <el-button size="small" @click="showTank = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import apis from './apis'
import {mapGetters} from "vuex";

export default {
  name: "hidden-danger-category",

  components: {},

  props: {},

  data() {
    return {
      dataTree: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      title: '新增隐患类别',
      showTank: false,
      formData: {},
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ]
      },
      tableList: [],
      searchData: {
        pageCurrent: 1,
        pageSize: 10,
        total: 0
      }
    }
  },

  computed: {
    ...mapGetters(['companyId','optionItem']),
    isTissueAdmin() {
      return this.optionItem && this.optionItem.roleNameList && this.optionItem.roleNameList.includes("组织管理员");
    },
    isAdmin() {
      return this.optionItem && this.optionItem.roleNameList && this.optionItem.roleNameList.includes("超级管理员")
    },
    // 是否为集团人员
    isCompany(){
      return this.optionItem && this.optionItem.departmentType == 'company';
    },
  },

  watch: {
    companyId() {
      this.init()
    }
  },

  mounted() {
    if (this.optionItem){
      console.log(this.optionItem)
    }

    if(this.companyId) this.init()
  },

  methods: {
    async init() {
      this.dataTree = await apis.getCategoryTree()
    },
    async getTableList() {
      const result = await apis.pageCategory(this.searchData)
      this.tableList = result.list
      this.searchData.total = result.total
    },
    // 节点被点击事件
    async handleNodeClick(node) {
      this.searchData.code = node.code
      this.getTableList().async
    },
    showFormTank() {
      this.title = '新增隐患类别'
      this.formData.parentId = 0
      this.isEdit = false
      this.showTank = true
    },
    close() {
      this.formData = {}
      this.$refs.form.resetFields()
    },
    save() {
      this.$refs.form.validate(async validate => {
        if (validate) {
          if(this.isEdit) await apis.editCategory(this.formData)
          else await apis.addCategory(this.formData)
          this.$message.success('操作成功')
          this.showTank = false
          this.init().async
        }
      })
    },
    add(data) {
      this.formData.parentId = data.id
      this.title = '新增隐患类别'
      this.isEdit = false
      this.showTank = true
    },
    edit(data) {
      this.title = '编辑隐患类别'
      this.formData.id = data.id
      this.$set(this.formData, 'name', data.label)
      this.isEdit = true
      this.showTank = true
    },
    enableOrDisable(data, status) {
      const title = status == 'star' ? '是否启用该隐患类别' : '是否作废该隐患类别'
      this.$confirm(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        if (status == 'star') await apis.enableCategory(data.id)
        else await apis.disableCategory(data.id)
        this.$message.success('操作成功')
        // this.init().async
        this.getTableList().async
        this.init().async
      })
    },  // 切换页码
    async handleSizeChangeData(val) {
      this.searchData.pageCurrent = val
      this.getTableList().async
    },
  }
}
</script>

<style lang="stylus" scoped>
.hidden-danger-category {
  height: 100%;
  .left {
    width 30%
    height 100%
    padding 10px
    border-right 1px solid #666
  }
  .right {
    width 70%
    height 100%
    padding 10px
  }
  .cont {
    padding: 10px 0;
  }
}
</style>
